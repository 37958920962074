import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";

import { addNewProduct } from "../../store/actions";
import Checkout from "../checkout";
import Spinner from "../reviews/Spinner";
import "./index.css";

export const Dropdown = ({ active, close }) => {
  const [auth, setAuth] = useState(false);
  const orders = useSelector(({ orders }) => orders.orders);
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const handleLoading = () => {
    setLoading(true);

    setInterval(() => {
      setLoading(false);
    }, 3000);
  };

  useEffect(() => {
    handleLoading();
  }, []);

  useEffect(() => {
    setAuth(Boolean(localStorage.getItem("sitEatID")));
  }, []);

  const deleteFromCart = (id) => {
    let customStore = orders;
    const existingItemKey = customStore.findIndex(
      (element) => element.product_id === id
    );

    //if the product already exists in the product store.
    if (existingItemKey >= 0) {
      customStore.splice(existingItemKey, 1);
    }
    addNewProduct(customStore, dispatch);
  };

  return (
    <>
      <div className={`dropdown ${active && "active"}`}>
        <i
          className="fas fa-times closeBtn sectionHead--mid mt-3 mb-3 isClickable"
          onClick={close}
        ></i>
        <Checkout page={true} />
      </div>
    </>
  );
};

const Cart = () => {
  return <div></div>;
};

export default Cart;
