import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  searchIcon,
  smallImg,
  stadium,
  // recycle, support
} from "../../assets";
import "./modal.css";
import { useSelector } from "react-redux";
import { Counter2 } from "../counter";
import * as Actions from "../../store/actions";
import { toast } from "react-toastify";
import {useDispatch} from 'react-redux';

const EditProfile = ({ changeView }) => {
  const closeModal = () => changeView(false);
  const [editableDetails, seteditableDetails] = useState({
    email: "",
    phoneNumber: "",
  });
  const userDetails = useSelector(({ auth }) => auth.userDetails);

  useEffect(() => {
    seteditableDetails({
      email: userDetails?.email,
      phoneNumber: userDetails?.phone,
    });
  }, [userDetails]);

  const submitModal = (e) => {
    e.preventDefault();
  };

  return (
    <div className="modal">
      <div>
        <div className="justify-between">
          <h2>Edit Profile</h2>
          <span datatype="close" onClick={closeModal}>
            &times;
          </span>
        </div>
        <form onSubmit={submitModal}>
          <input
            type="email"
            placeholder="Email address"
            required
            value={editableDetails.email}
          />
          <input
            type="number"
            placeholder="Phone number"
            required
            minLength={10}
            value={editableDetails.phoneNumber}
          />
          <div className="justify-between">
            <button type="button" onClick={closeModal}>
              CANCEL
            </button>
            <button type="submit">UPDATE</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteModal = ({ setActive, submit }) => {
  const closeModal = () => setActive(false);

  const submitModal = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <div className="modal">
      {" "}
      {/* overlay */}
      <div>
        {" "}
        {/* modal box */}
        <div className="justify-between">
          <h2>Delete</h2>
          <span datatype="close" onClick={closeModal}>
            &times;
          </span>
        </div>
        <form onSubmit={submitModal}>
          <p>Are you sure you want to delete XXXX</p>
          <div className="justify-between">
            <button type="button" onClick={closeModal}>
              CANCEL
            </button>
            <button type={"submit"}>DELETE</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ReceiptModal = ({ setActive, data }) => {
  const closeModal = () => setActive(false);
  const { item, orders } = data;

  return (
    <div className="modal">
      <div className="flex receipt">
        <img src={smallImg} alt=" " />

        <div className="flex-column justify-between orderCardText">
          <p>
            <b>{item.order_status.name.toUpperCase()}</b>
            {":  "}
            {new Date(item.created_at).toLocaleDateString() +
              " - " +
              new Date(item.created_at).toLocaleTimeString()}
          </p>
          <p>
            <b>{`${item.transaction.currency} ${item.transaction.amount}`}</b>
          </p>
          {orders.map((product) => (
            <p key={Math.random()}>
              <span>
                {product.name} ({product.quantity}) -{" "}
                {item.transaction.currency} {product.amount * product.quantity}
              </span>
            </p>
          ))}
          <p>
            <b>{`Vendor: ${item.vendor_details.company_name}`}</b>
          </p>
          <p>
            <b>{`Stall: ${item.stall_id.name} / ${item.stall_id.location}`}</b>
          </p>

          {/* <div id="receipt">
                        <div className="justify-between">
                            <button className="flex"><img src={recycle} alt="recycle" />REORDER</button>
                            <button className="flex"><img src={support} alt="recycle" />HELP</button>
                        </div>
                    </div> */}
        </div>

        <span datatype="close" onClick={closeModal}>
          &times;
        </span>
      </div>
    </div>
  );
};

const SelectEventModal = ({ toggleFunc }) => {
  const stadiums = useSelector(({ orders }) => orders.stadiums);
  const [sorted, setStadiums] = useState([]);
  const [searchString, setSearchString] = useState("");

  const submitMe = (e) => {
    e.preventDefault();
    const sortedArray = stadiums.filter((element) =>
      element.name.toLowerCase().includes(searchString.toLowerCase())
    );
    setStadiums(sortedArray);
  };

  useEffect(() => {
    const sortedArray = stadiums.filter((element) =>
      element.name.toLowerCase().includes(searchString.toLowerCase())
    );
    setStadiums(sortedArray);
  }, [searchString, stadiums]);

  // console.log(stadiums);

  return (
    <div className="modal">
      <div id="selectEvent">
        <div>
          <div className="flex">
            <img src={stadium} alt=" " />
            <p className="colorPrimary"> Select Venue</p>
          </div>
          <p
            datatype="close"
            className="colorPrimary"
            onClick={() => toggleFunc(false)}
          >
            &times;
          </p>
          <form onSubmit={submitMe}>
            <img src={searchIcon} alt="search Icon" />
            <input
              placeholder="Search"
              type="search"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <button type="submit" className="primaryBG">
              {" "}
              Go!{" "}
            </button>
          </form>
        </div>
        <div>
          <ul>
            {sorted?.length > 0 ? (
              sorted.map((item) => {
                let url = item.name.replace(/\s+/g, '-').toLowerCase()
                return (
                  <Link
                    to={{
                      pathname: `/vendors/${url}/${item.id}`,
                      // state: item
                    }}
                    key={item.id}
                    onClick={() => localStorage.setItem("sitEatVenue", item.id)}
                  >
                    <li key={item.id}>{item.name}</li>
                  </Link>
                );
              })
            ) : (
              <p style={{ textAlign: "center", color: "gray" }}>
                No venue found
              </p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const SelectStallModal = ({ toggleFunc }) => {
  const stalls = useSelector(({ orders }) => orders.stalls);
  const [sorted, setStalls] = useState([]);

  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const sortedArray = stalls.filter((element) =>
      element.name.toLowerCase().includes(searchString.toLowerCase())
    );
    setStalls(sortedArray);
  }, [searchString, stalls]);

  const submitMe = (e) => {
    e.preventDefault();
    const sortedArray = stalls.filter((element) =>
      element.name.toLowerCase().includes(searchString.toLowerCase())
    );
    setStalls(sortedArray);
  };

  return (
    <div className="modal">
      <div id="selectEvent">
        <div>
          <div className="flex">
            <p className="colorPrimary"> Select pickup location</p>
          </div>
          <p
            datatype="close"
            className="colorPrimary"
            onClick={() => toggleFunc(false)}
          >
            &times;
          </p>
          <form onSubmit={submitMe}>
            <img src={searchIcon} alt="search Icon" />
            <input
              placeholder="Search"
              type="search"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <button type="submit" className="primaryBG">
              {" "}
              Go!{" "}
            </button>
          </form>
        </div>
        <div>
          <ul>
            {sorted?.length > 0 ? (
              sorted.map((item) => (
                <Link
                  to={{
                    pathname: `/otp`,
                    state: true,
                  }}
                  key={item.id}
                  onClick={() => localStorage.setItem("stallId", item.id)}
                >
                  <li key={item.id}>{item.name}</li>
                </Link>
              ))
            ) : (
              <p style={{ textAlign: "center", color: "gray" }}>
                No stall found
              </p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const DesktopNavModal = ({ setActive, editProfile, auth, logout }) => {
  const checkEvent = (e) => {
    if (!e.target.id) {
      setActive(false);
    }
  };

  return (
    <div className="modal modal2" onClick={checkEvent}>
      <div id="desktopNav" className="flex-column" onClick={checkEvent}>
        {Boolean(auth) ? (
          <ul>
            {/* <li><NavLink to="/favourite_list"><img src={redHeart} alt="" /><p>Favourites</p></NavLink></li> */}
            {/* <li><NavLink to="/payments"><img src={creditCard} alt="" /> <p>Payment</p></NavLink></li>
                            <li><NavLink to="#" onClick={editProfile}><img src={whiteUser} alt="profile" /><p>Edit Profile</p></NavLink></li> */}
            <li>
              <NavLink to="#" onClick={logout}>
                Logout
              </NavLink>
            </li>
          </ul>
        ) : (
          <div className="flex-column">
            <NavLink to="/" className="primaryBG">
              Sign in
            </NavLink>
            <span />
            <p>or</p>
            <NavLink to="/signup" className="secondaryBG">
              Sign Up
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

const ProductModal = ({ open, setOpen, modalItem,orders }) => {
  // console.log(modalItem);
  const dispatch = useDispatch();
  const [extraItems,setExtraItems] = useState([

  ])
  const closeModal = () => {
    setOpen(false);
    setExtraItems([])
    document.body.classList.remove("no-scroll");
  };
  const handleExtraItem = (extra,item,extraIndex,itemIndex)=>{
    let mainExtras = [...extraItems];
    if(mainExtras[extraIndex]){
      if(mainExtras[extraIndex].items[itemIndex]){
        mainExtras[extraIndex].items.splice(itemIndex,1)
      }
      else{
        mainExtras[extraIndex].items.push(item)
      }
    }
    else{
      mainExtras.push(
        {
          name:extra.name,
          items:[item]
        }
      )
    }
    setExtraItems(mainExtras) 

  }
  const handleCart = ()=>{
    let customStore = orders;
    const existingItemKey = customStore.findIndex(
      (element) => element.product_id === modalItem.id
    );
    let extraTotal = 0;
    const calculatePrice = extraItems.forEach(extra=>{
      extra.items.forEach(item=>{
        extraTotal = extraTotal + Number(item.price)
      })
    })
    if (existingItemKey >= 0) {
      //if the product already exists in the product store.
      // customStore[existingItemKey].product_qty += 1;
      customStore[existingItemKey].add_ons=extraItems
    } else {
      let payload = {
        product_id: modalItem.id,
        product_name: modalItem.name,
        product_amount: Number(modalItem.price) + extraTotal,
        product_qty: 1,
        add_ons: extraItems,
      };
      customStore.push(payload);
    }
    toast("Item added to cart successfully");
    Actions.addNewProduct(customStore, dispatch);
    closeModal()
  }
  return (
    <>
      {open && (
        <div className="productModal">
          <div className="options">
            <span className="close" onClick={closeModal}>
              <h1 className="close-img"> &times;</h1>
            </span>
            <div className="option-header">
              <h1>{modalItem.name}</h1>
              <p>{modalItem.description}</p>
            </div>
            <div
              className="modal-img"
              style={{ backgroundImage: `url(${modalItem.image_url})` }}
            ></div>
            <div className="options-main">
              <h1>Select Extras</h1>
              {modalItem.add_ons.map((extra, extraIndex) => {
                return (
                  <div key={extraIndex} className="extras">
                    {/* <input
                      key={v.variation_id}
                      type="radio"
                      value={v.variation_id}
                      // checked={v.variation_id == this.state.giftCard}
                    /> */}
                    <h5> {extra.name}</h5>
                    <p>Select up to 2</p>
                    {extra.items.map((item,itemIndex) => {
                      return (
                        <div className="extras-item" key={itemIndex}>
                          <div className="extras-item-check">
                            <input
                              key={item.price}
                              type="checkbox"
                              value={item.name}
                              onChange={()=>handleExtraItem(extra,item,extraIndex,itemIndex)}
                            />
                            <p> {item.name}</p>
                          </div>
                          <div className="extra-item-price">
                            <p> +${item.price}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className="fixed-footer">
              <div className="fixed-footer-main">
                <div className="footer-main">
                  {/* <Counter2 /> */}
                  <button className="modal-button" onClick={handleCart}>
                    Add item to cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export {
  DeleteModal,
  EditProfile,
  ReceiptModal,
  SelectEventModal,
  SelectStallModal,
  DesktopNavModal,
  ProductModal,
};
