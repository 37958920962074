import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "../../api";
import requestProcessor from "../../api/requestProcessor";
// import * as OrderActions from "./orders.actions";

export const AUTH_LOGIN = "AUTH LOGIN";
export const AUTH_LOADING = "AUTH LOADING";
export const AUTH_NOT_LOADING = "AUTH NOT LOADING";
export const USER_DETAILS = "USER DETAILS";
export const AUTH_ERROR = "AUTH ERROR LOGIN";
export const AUTH_ERROR_SIGNUP = "AUTH ERROR SIGNUP";
export const AUTH_LOGOUT = "AUTH LOGOUT";
export const AUTH_ALERT = "AUTH ALERT";
export const AUTH_PHONE = "AUTH PHONE";

// export const Login = (payload, dispatch, history) => {
//   dispatch({ type: AUTH_LOADING });

//   return requestProcessor
//     .sendPost(`${baseURL()}/login`, payload)
//     .then((res) => {
//       if (res && String(res.status).startsWith("2")) {
//         const { name, email, phone, token } = res.data;

//         dispatch({ type: USER_DETAILS, payload: { name, email, phone } });
//         dispatch({ type: AUTH_LOGIN, payload: token });

//         localStorage.setItem("sitEatID", token);
//         axios.defaults.headers["Authorization"] = `Bearer ${token}`;
//         localStorage.setItem(
//           "sitEatUserDetail",
//           JSON.stringify({ name, email, phone })
//         );

//         // history.push("/");
//         window.location.reload(); //bad practice
//       } else {
//         toast(res.data.message);
//         dispatch({ type: AUTH_ERROR, payload: "Invalid Login Credentials" });
//       }
//     })
//     .catch((err) => {
//       toast("Invalid login credentials");
//       console.log("error");
//       err?.response?.data?.error
//         ? dispatch({ type: AUTH_ERROR, payload: err.response.data.error })
//         : dispatch({ type: AUTH_ERROR, payload: "Invalid Login Credentials" });
//       setTimeout(() => {
//         dispatch({ type: AUTH_ERROR, payload: "" });
//       }, 3000);
//     })
//     .finally(() => {
//       dispatch({ type: AUTH_NOT_LOADING });
//     });
// };



export const LoginUser = async(userDetails, history) => {
    try {
        const res = await axios.post('https://api.siteat.org/api/v2/login', userDetails);
        console.log(res.data);

        if (res && res.data && res.data.success === true) {
            toast(res.data.message);
            const { data, token } = res.data;
            const { name, phone, email } = data;
            axios.defaults.headers["Authorization"] = `Bearer ${token}`;

            const resUserDetails = {
                name,
                phone,
                email
            }

            localStorage.setItem('sitEatId', token);
            localStorage.setItem('sitEatUserDetail', JSON.stringify(resUserDetails));

            history.push({ pathname: "/otp", state: data })
        } else {
            toast(res.data && res.data.message)
        }
    } catch (err) {
        console.log(err);
    }
}


export const OTP = (payload, dispatch, history) => {
    dispatch({ type: AUTH_LOADING });

    return requestProcessor
        .sendPost(`${baseURL()}/send-otp`, payload)
        .then((res) => {
            if (res && String(res.status).startsWith("2")) {
                console.log(payload.identifier)
                    // console.log(res.data)
                    // const { identifier } = res.data;
                    // console.log(identifier)
                dispatch({ type: AUTH_PHONE, payload: payload.identifier })
                toast(res.data.message);
                history.push({ pathname: "/otpinput", state: payload });
            } else {
                toast(res.data.message);
                dispatch({ type: AUTH_ERROR, payload: "Invalid Login Credentials" });
            }
        })
        .catch((err) => {
            toast("Invalid login credentials");
            console.log("error");
            // err?.response?.data?.error ?
            setTimeout(() => {}, 3000);
        })
        .finally(() => {});
};

export const handleToken = (payload, history, dispatch) => {
    return requestProcessor
        .sendPost(`${baseURL()}/verify-otp`, payload)
        .then((res) => {
            if (res) {
                console.log(res)
                if (String(res.status).startsWith("2")) {

                    const { data, token } = res.data;

                    dispatch({ type: USER_DETAILS, payload: data });
                    dispatch({ type: AUTH_LOGIN, payload: token });

                    localStorage.setItem("sitEatID", token);
                    axios.defaults.headers["Authorization"] = `Bearer ${token}`;
                    localStorage.setItem(
                        "sitEatUserDetail", JSON.stringify({...data, firstTimeUser: !data.first_name, type: payload.type })
                    );

                    // OrderActions.payIntent(token, totalAmount, data, history);
                    history.push({ pathname: "/payments", state: payload });
                } else {
                    toast(res.data.message);
                }
            } else {
                toast("Invalid login credentials");
            }
        })
        .catch((err) => {
            toast("Invalid login credentials");
            console.log("error: ", err);
            // err?.response?.data?.error ?
            setTimeout(() => {}, 3000);
        })
        .finally(() => {});
};

// export const SignUp = (payload, dispatch) => {
//   dispatch({ type: AUTH_LOADING });

//   return requestProcessor
//     .sendPost(`${baseURL()}/register`, payload)
//     .then((res) => {
//       console.log(res);
//       if (res && String(res.status).startsWith("2")) {
//         const { data, token } = res.data;

//         localStorage.setItem("sitEatID", token);
//         localStorage.setItem("sitEatUserDetail", JSON.stringify(data));
//         axios.defaults.headers["Authorization"] = `Bearer ${token}`;

//         window.location.reload(); //bad practice
//       } else {
//         toast(res.data.message);
//         dispatch({ type: AUTH_ERROR_SIGNUP, payload: res.data.message });
//       }
//     })
//     .catch((err) => {
//       console.log(err, "err");
//     })
//     .finally(() => {
//       dispatch({ type: AUTH_NOT_LOADING });
//     });
// };

export const registerUser = async(userDetails, history) => {

    try {
        const res = await axios.post('https://api.siteat.org/api/v2/register', userDetails)

        if (res && res.data && res.data.success === true) {
            toast('Signup Successful, Redirecting...')
            const { data, token } = res.data;

            axios.defaults.headers["Authorization"] = `Bearer ${token}`;

            localStorage.setItem('sitEatId', token);
            localStorage.setItem('sitEatUserDetail', JSON.stringify(data));

            history.push({ pathname: '/otp', state: data })
        } else {
            toast(res.data && res.data.message)
        }
    } catch (err) {
        console.log('Registration Failed, please try again');
    }
}

export const Logout = (dispatch, history) => {
    dispatch({ type: AUTH_LOGOUT });
    localStorage.clear();
    history.go(0);
};

export const resetPass = async(email) => {
    try {
        const res = await axios.post(process.env.REACT_APP_auth_base + '/forgot-password', email)
        toast(res && res.data && res.data.message)
    } catch (err) {
        toast(err && err.message);
    }
}