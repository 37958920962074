import * as Actions from '../actions';

const initialState = {
    loading: false,
    stadiums: [],
    orders: [],
    vendorDetails: {},
    history: [],
    stalls: [],
    paymentLoading: false,
    generalSettings:null,
    tip:0
}

export const OrdersReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case Actions.GET_ALL_STADIUMS:
            return {
                ...state,
                stadiums: actions.payload,
                loading: false
            }
        case Actions.GET_HISTORY:
            return {
                ...state,
                history: actions.payload,
                loading: false
            }
        case Actions.ADD_NEW_PRODUCT:
            localStorage.setItem("sitEatCart", JSON.stringify(actions.payload));
            return {
                ...state,
                orders: actions.payload,
                loading: false
            }
        case Actions.GET_CART:
            let cart = JSON.parse(localStorage.getItem("sitEatCart"));
            let vendor = JSON.parse(localStorage.getItem("sitEatVendor"));
            return {
                ...state,
                vendorDetails: vendor,
                orders: cart
            }
        case Actions.UPDATE_VENDOR:
            localStorage.setItem("sitEatVendor", JSON.stringify(actions.payload));
            return {
                ...state,
                vendorDetails: actions.payload,
                loading: false
            }
        case Actions.PAYMENT_LOADING:
            return {
                ...state,
                paymentLoading: true
            }
        case Actions.GET_ALL_STALLS:
            return {
                ...state,
                stalls: actions.payload
            }
        case Actions.UPDATE_TIP:
            return{
                ...state,
                tip:actions.payload<0?0:actions.payload
            }
        case Actions.GET_GENERAL_SETTINGS:
            return {
                ...state,
                generalSettings: actions.payload,
            }
        default:
            return state
    }
}