import { motion } from "framer-motion";
import React, { useEffect, useState, setState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import { SelectStallModal } from "../../components/modal";
import { ToastContainer } from "react-toastify";
import {
  arrowDown,
  booth,
  cart,
  sale,
  whiteBooth,
  whiteSale,
  whiteUser,
  blackcart,
  logo192,
} from "../../assets";
import * as Actions from "../../store/actions";
import { Divider } from "../divider";
import { Footer } from "../footer";
import "../layout/layout.css";
import { toast } from "react-toastify";
import { DesktopNavModal, EditProfile } from "../modal";
import { ProductCard } from "../productCard";
import "./header.css";
import { Getmobileapp } from "../getmobileapp";
import { Dropdown } from "../cart";

const Header = ({ blue }) => {
  const [auth, setAuth] = useState("");
  const orders = useSelector(({ orders }) => orders.orders);
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectStall, setSelectStall] = useState(false);

  const [showLeftHamburger, setShowLeftHamburger] = useState(false);
  const [showRightHamburger, setShowRightHamburger] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showDesktopModal, setShowDesktopModal] = useState(false);
  const [reload, setReload] = useState(Math.random());
  const vendorDetails = useSelector(({ orders }) => orders.vendorDetails);

  const toggleLeft = () => setShowLeftHamburger(!showLeftHamburger);
  const toggleRight = () => setShowRightHamburger(!showRightHamburger);

  const logout = () => {
    Actions.Logout(dispatch, history);
  };

  useEffect(() => {
    setAuth(Boolean(localStorage.getItem("sitEatID")));
  }, []);

  const addToCart = ({ id, name, price }) => {
    let customStore = orders;
    const existingItemKey = customStore.findIndex(
      (element) => element.product_id === id
    );

    //if the product already exists in the product store.
    if (existingItemKey >= 0) {
      customStore[existingItemKey].product_qty += 1;
    } else {
      let payload = {
        product_id: id,
        product_name: name,
        product_amount: price,
        product_qty: 1,
      };
      // customStore.push(payload)
      customStore = payload;
    }

    // console.log(customStore, orders, existingItemKey);
    Actions.addNewProduct(customStore, dispatch);
  };

  const subTractFromCart = ({ id }) => {
    let customStore = orders;
    const existingItemKey = customStore.findIndex(
      (element) => element.product_id === id
    );

    //if the product already exists in the product store.
    if (existingItemKey >= 0) {
      if (customStore[existingItemKey].product_qty > 1) {
        customStore[existingItemKey].product_qty -= 1;
      } else customStore.splice(existingItemKey, 1);
    }
    Actions.addNewProduct(customStore, dispatch);
  };

  const deleteFromCart = ({ id }) => {
    let customStore = orders;
    const existingItemKey = customStore.findIndex(
      (element) => element.product_id === id
    );

    //if the product already exists in the product store.
    if (existingItemKey >= 0) {
      customStore.splice(existingItemKey, 1);
    }
    Actions.addNewProduct(customStore, dispatch);
  };

  const cartFunctions = ({ type, payload }) => {
    const { id, name, price } = payload;

    switch (type) {
      case "addToCart":
        addToCart({ id, name, price });
        setReload(Math.random());
        break;

      case "subTractFromCart":
        subTractFromCart({ id });
        setReload(Math.random());
        break;

      case "deleteFromCart":
        deleteFromCart({ id });
        setReload(Math.random());
        break;

      default:
        setReload(Math.random());
        break;
    }
  };

  const convenienceFee = Number(
    0.1 *
      orders.reduce(
        (acc, value) => acc + value.product_amount * value.product_qty,
        0
      )
  );
  const totalAmount = Number(
    orders.reduce(
      (acc, value) => acc + value.product_amount * value.product_qty,
      0
    ) + convenienceFee
  );

  const subTotal = orders.reduce(
    (acc, value) => acc + value.product_amount * value.product_qty,
    0
  );

  const [Cartdrop, setCartDrop] = useState(false);

  const openModal = () => {
    setCartDrop(!Cartdrop);
    console.log(!Cartdrop);
  };

  return (
    <>
      <aside className={`leftNav${showLeftHamburger ? " activeLeftNav" : ""}`}>
        <div>
          <p datatype="close" onClick={toggleLeft}>
            &times;
          </p>
        </div>
        <ul>
          <li>
            <NavLink onClick={toggleLeft} to="/">
              <img src={booth} alt="" /> Home
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink onClick={toggleLeft} to="/order_history">
              <img src={sale} alt="" />
              Orders
            </NavLink>
          </li>
          {/* <li>
            {" "}
            <NavLink onClick={toggleLeft} to="/favourite_list">
              <img src={booth} alt="" />
              Favourites
            </NavLink>
          </li> */}

          {/* <li>
            {" "}
            <NavLink onClick={toggleLeft} to="/payments">
              <img src={creditCard} alt="" />
              Payment
            </NavLink>
          </li> */}

          {/* <li>
            {" "}
            <p onClick={() => setShowProfile(true)}>
              <img src={booth} alt="" />
              Edit Profile
            </p>
          </li> */}
          {auth ? (
            <li>
              <p onClick={logout}>
                <img src={booth} alt="" />
                Logout
              </p>
            </li>
          ) : (
            <></>
          )}
        </ul>
        <Divider />
        <Footer />
      </aside>

      <aside
        key={reload}
        className={`rightNav${showRightHamburger ? " activeRightNav" : ""}`}
      >
        <p datatype="close" onClick={toggleRight}>
          &times;
        </p>
        <p>Your Order</p>
        <p>{vendorDetails?.company_name ?? "..."}</p>

        <div
          style={{
            overflow: "scroll",
            flex: 1,
            border: "1px solid #a9a9a973",
          }}
        >
          {orders?.map((item, index) => (
            <ProductCard
              details={item}
              key={index}
              cartFunctions={cartFunctions}
            />
          ))}
        </div>

        <div
          style={{
            marginTop: "auto",
            padding: "0 20px",
          }}
        >
          <div
            style={{
              padding: "0.2em 0em",
              border: "none",
            }}
            className="justify-between"
          >
            <span>Items</span>
            <span>{orders.length}</span>
          </div>
          <div
            style={{
              padding: "0.2em 0em",
              border: "none",
            }}
            className="justify-between"
          >
            <span>Convenience Fee</span>
            <span>$ {convenienceFee}</span>
          </div>

          <div
            style={{
              padding: "0.2em 0em",
              border: "none",
            }}
            className="justify-between"
          >
            <span>Sub Total</span>
            <span>$ {subTotal}</span>
          </div>

          <div
            style={{
              padding: "0.2em 0em",
              border: "none",
            }}
            className="justify-between"
          >
            <span>Total</span>
            <span>$ {totalAmount}</span>
          </div>

          <div
            style={{
              padding: "0.6em 0.5em",
              border: "none",
              width: "auto",
            }}
            onClick={() =>
              orders.length > 0
                ? () => {
                    setSelectStall(true);
                  }
                : "#"
            }
            className={`flex primaryBG checkoutBtn visible`}
          >
            <h3>{"Checkout"}</h3>
          </div>

          {selectStall ? (
            <SelectStallModal toggleFunc={setSelectStall} />
          ) : (
            <></>
          )}

          {/* <Link
                                                      style={{
                                                               padding: "0.6em 0.5em",
                                                               border: "none",
                                                               width: "100%",
                                                               marginLeft: 0,
                                                      }}
                                                      to={
                                                               orders.length > 0
                                                                        ? {
                                                                                   pathname: "/otp",
                                                                                   state: true,
                                                                          }
                                                                        : "#"
                                                      }
                                                      className="justify-between primaryBG checkoutBtn"
                                             >
                                                      <h3>{"Checkout"}</h3>
                                                      <span>
                                                               $ {totalAmount}
                                                      </span>
                                             </Link> */}
        </div>
      </aside>

      <ToastContainer />

      <header className={`${blue ? "header" : "headers"}`}>
        {/* {auth ? (
					) : (
						<p></p>
					)} */}

        <div className="container spaceBetween">
          {/* <motion.img
                                             initial={{ opacity: 0 }}
                                             animate={{ opacity: 1 }}
                                             exit={{ opacity: 0 }}
                                             src={menuTab}
                                             alt="sit eat jamburger"
                                             onClick={toggleLeft}
                                    /> */}

          <NavLink to={"/"}>
            <div className="sitEatLogo">
              <h2 id="siteat">SitEat</h2>
              <img src={logo192} alt="" className="logo-Img" />
            </div>
          </NavLink>

          <div className="menu-Nav">
            <ul>
              {auth ? (
                <motion.li
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                >
                  {" "}
                  <NavLink
                    activeClassName="menu-Nav-Active"
                    to="/order_history"
                  >
                    {/* <img src={whiteBooth} alt="" /> */}
                    Orders
                  </NavLink>
                </motion.li>
              ) : (
                <></>
              )}


              {/* {auth ? (
                <motion.li
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                >
                  {" "}
                  <NavLink
                    onClick={() => setShowDesktopModal(!showDesktopModal)}
                    className={showDesktopModal ? "menu-Nav-Active" : ""}
                    to="#"
                  >
                    <img src={whiteUser} alt="" />
                    Account
                    <img src={arrowDown} alt="" />
                  </NavLink>
                </motion.li>
              ) : (
                <></>
              )} */}
            </ul>

            <div className="isRelative desktop__cart">
              <p className="forVendors">For vendors</p>
              <div className="Getmobileapp_mobile">
                <Getmobileapp />
              </div>

              <div>
                <img src={blackcart} alt="shopping cart icon" />
                <span className="amount cart-num center">{orders.length}</span>
              </div>
            </div>

            <div onClick={openModal} className="isRelative mobile__cart">
              <img src={blackcart} alt="shopping cart icon" />
              <span className="amount cart-num center">{orders.length}</span>
            </div>

            {Cartdrop && (
              <Dropdown
                active={Cartdrop}
                close={() => setCartDrop(!Cartdrop)}
              />
            )}
          </div>
        </div>
      </header>

      {showProfile && <EditProfile changeView={setShowProfile} />}

      {showDesktopModal && (
        <DesktopNavModal
          editProfile={setShowProfile}
          setActive={setShowDesktopModal}
          auth={auth}
          logout={logout}
        />
      )}
    </>
  );
};

export { Header };
