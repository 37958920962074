import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { MainRoute, PrivateRoute } from "./routes";
import * as Actions from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import  Login  from "./pages/auth/login";
import SignUp from "./pages/auth/signup";
import Forgot from "./pages/auth/forgotPassword";
import Error from "./pages/app/error";
// import OTP from './pages/auth/Otp';

// routes
const AboutUs = React.lazy(() => import("./pages/app/aboutUs"));
const Contact = React.lazy(() => import("./pages/app/contactUs"));
// const Login = React.lazy(() => import("./pages/auth/login"));
// const SignUp = React.lazy(() => import("./pages/auth/signup"));
const OTP = React.lazy(() => import("./pages/auth/Otp"));
const OTPInput = React.lazy(() => import("./pages/auth/OtpInput"));
const EventCenter = React.lazy(() => import("./pages/app/eventCenter"));
const FAQ = React.lazy(() => import("./pages/app/faq"));
const FavouriteList = React.lazy(() => import("./pages/app/favouriteList"));
const Home = React.lazy(() => import("./pages/app/home"));
const Vendor = React.lazy(() => import("./pages/app/vendor"));
const MealOrder = React.lazy(() => import("./pages/app/mealOrder"));
const Offers = React.lazy(() => import("./pages/app/offers"));
const OrderHistory = React.lazy(() => import("./pages/app/orderHistory"));
const Payments = React.lazy(() => import("./pages/app/payments"));
const PrivacyPolicy = React.lazy(() => import("./pages/app/privacyPolicy"));
const Terms = React.lazy(() => import("./pages/app/termsAndConditions"));
const VendorRegistration = React.lazy(() =>
	import("./pages/app/vendorRegistration")
);
const RequestDemo = React.lazy(() => import("./pages/app/requestDemo"));

function AppRoute() {
	const dispatch = useDispatch();
	const auth = useSelector(({ auth }) => auth.token);
	const [isAuthenticated, setisAuthenticated] = useState(
		Boolean(localStorage.getItem("sitEatID"))
	);

	useEffect(() => {
		setisAuthenticated(Boolean(localStorage.getItem("sitEatID")));
		const token = localStorage.getItem("sitEatID");
		if (token) {
			axios.defaults.headers["Authorization"] = `Bearer ${token}`;
			dispatch({ type: Actions.GET_CART });
		}

		dispatch({
			type: Actions.AUTH_LOGIN,
			payload: localStorage.getItem("sitEatID"),
		});
	}, [auth, dispatch]);

	return (
		<React.Suspense fallback={<></>}>
			<Router>
				<Switch>
					{/* auth route for unauthenticated routes */}

					<MainRoute path='/login' component={Login} exact isAuthenticated={isAuthenticated}/>
					<MainRoute path='/register' component={SignUp} exact isAuthenticated={isAuthenticated}/>
					<MainRoute path='/forgot' component={ Forgot } exact isAuthenticated={ isAuthenticated } />

					{/* <AuthRoute path="/" Component={Login} exact isAuthenticated={isAuthenticated} />
                    <AuthRoute path="/signup" Component={SignUp} exact isAuthenticated={isAuthenticated} /> */}

					{/* main route for authorized routes */}
					<MainRoute
						path="/"
						Component={Home}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<MainRoute
						path="/vendor_registration"
						Component={VendorRegistration}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<MainRoute
						path="/frequently_asked_questions"
						Component={FAQ}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<MainRoute
						path="/request_demo"
						Component={RequestDemo}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<MainRoute
						path="/terms_and_conditions"
						Component={Terms}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<MainRoute
						path="/privacy_policy"
						Component={PrivacyPolicy}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<MainRoute
						path="/contact_us"
						Component={Contact}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					{/* <MainRoute component={Error} exact/> */}
					<MainRoute
						path="/about_us"
						Component={AboutUs}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<PrivateRoute
						path="/events/:id"
						Component={EventCenter}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<MainRoute
						path="/vendors/:url/:id"
						Component={Vendor}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<PrivateRoute
						path="/order/:id"
						Component={MealOrder}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<PrivateRoute
						path="/offers"
						Component={Offers}
						exact
						isAuthenticated={isAuthenticated}
					/>
					<MainRoute
						path="/otp"
						component={OTP}
						exact
						isAuthenticated={ isAuthenticated || !isAuthenticated}
					/>
					<MainRoute
						path="/otpinput"
						component={OTPInput}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<PrivateRoute
						path="/payments"
						Component={Payments}
						exact
						payment
						isAuthenticated={isAuthenticated}
					/>
					<PrivateRoute
						path="/favourite_list"
						Component={FavouriteList}
						exact
						isAuthenticated={isAuthenticated || !isAuthenticated}
					/>
					<PrivateRoute
						path="/order_history"
						Component={OrderHistory}
						exact
						isAuthenticated={isAuthenticated}
					/>
					<PrivateRoute path="*" render={<Redirect to="/" />} />
				</Switch>
			</Router>
		</React.Suspense>
	);
}

export default AppRoute;
