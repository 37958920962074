import { baseURL } from './config';
import axios from 'axios';

axios.defaults.headers["Accept"] = "application/json";
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.baseURL = baseURL();

// var refreshing = false;
// var count = 0;

//interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error) {
        // let config = error.config;
        if (error.response) {
            // if (error.response.status === 401 && config.url.toLowerCase() !== '/auth/authenticate') {
            //     //make refreshtoken request
            //     if (!refreshing && count <= 5) {
            //         refreshing = true;
            //         count++;
            //         return fetch(`${config.baseURL}/auth/refresh_token`, { method: 'PUT', headers: { ...config.headers } })
            //             .then(result => {
            //                 return result.json();
            //             })
            //             .then(res => {
            //                 if (res.data) {
            //                     let { access_token, callback_token } = res.data.authDetails;
            //                     config.headers["x-access-token"] = access_token;
            //                     config.headers["callback-code"] = callback_token;
            //                     axios.defaults.headers["x-access-token"] = access_token;
            //                     axios.defaults.headers["callback-code"] = callback_token;
            //                     localStorage.setItem('_siteat_auth_details', JSON.stringify(res.data.authDetails));
            //                     refreshing = false;
            //                     count = 0;
            //                     return axios(config);
            //                 } else {
            //                     return Promise.reject(res);
            //                 }
            //             }).catch(error => {
            //                 return Promise.reject(error);
            //             });
            //     }
            // } if (error.response.status === 401 && config.url.toLowerCase() === '/auth/authenticate' && error.headers["x-authorization-error"] === 'invalid_token') {
            //     return Promise.reject(error.response);
            // } else {
            //     if (error.response.status.toString().startsWith('5')) {
            //         return Promise.reject(error);
            //     } else {
            //         return Promise.resolve(error.response);
            //     }
            // }
        } else {
            //return internet connection errors;
            return Promise.reject(error);
        }
    }
});

class processRequest {
    sendGet(url) {
        return axios.get(url);
    }

    sendPost(url, payload = {}) {
        return axios.post(url, payload);
    }

    async sendFormData(url, payload = {}) {
        return axios({
            url: url,
            method: 'POST',
            data: payload,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        });
    };

    sendPut(url, payload) {
        return axios.put(url, payload);
    }

    sendDelete(url, payload) {
        return axios.delete(url, payload);
    }

    sendPatch(url, payload) {
        return axios.patch(url, payload);
    }
}

export default new processRequest(); 