export const mainBlack = '#696969';
export const calculateAmount = (state)=>{
    let generalSettings = JSON.parse(localStorage.getItem('generalSettings'));
    let totalOrderAmount = Number(state.orders.reduce((acc, value) => acc + value.product_amount * value.product_qty, 0));
    let convenienceFee = 
    (0.1) * totalOrderAmount;
    convenienceFee = Math.round((convenienceFee + Number.EPSILON) * 100) / 100
    const totalAmount = Number(totalOrderAmount + convenienceFee+ Number(state.tip?state.tip:0));
    const totalAmountShow = totalAmount.toFixed(2);
    const subTotalShow = totalOrderAmount.toFixed(2);
    const convenienceFeeShow = convenienceFee.toFixed(2);
    
    return {
        totalOrderAmount,
        convenienceFee,
        totalAmount,
        totalAmountShow,
        subTotalShow,
        convenienceFeeShow
    }
}