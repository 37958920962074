import React, {useState, Fragment} from 'react';
import Spinner from '../../../components/reviews/Spinner';
import { resetPass} from '../../../store/actions/auth.actions';
import {Header} from "../../../components";
import "react-toastify/dist/ReactToastify.css";
import '../login/login.css'

const Forgot = () => {

    const [isLoading, setIsLoading] = useState(false);

    const handleLoading = () => {
        setIsLoading(true);

        setInterval(() => {
            setIsLoading(false)
        }, 6000)
    }


    const [formData,
        setFormData] = useState({email: ''})

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
       handleLoading();
        resetPass(formData);
    }

    const {email} = formData;

    return (
        <Fragment>
            <Header/>
            <div className="container authContent alignOnly">
                <div className="authFormCard">
                    <h2 className="vAlign textIsPink sectionText mb-3 mt-3">Reset Password
                    </h2>
                    <form className='w-100 mt-4' onSubmit={handleSubmit}>
                        <div className="inputHolder mt-2">
                            <input
                                type="email"
                                placeholder='Enter Email'
                                name='email'
                                value={email}
                                onChange={handleChange}/>
                        </div>
                        <div
                            className="btnHolder mt-5"
                            style={{
                            textAlign: "center"
                        }}>
                            { isLoading
                                ? <button
                                    disabled
                                    className='defaultBtn center w-100'
                                    style={ {
                                        marginRight: '15px'
                                    } }>Please Wait
                                    <Spinner />
                                </button>
                                : <button type='submit' className="defaultBtn w-100">Send</button> }

                        </div>

                    </form>
                </div>
            </div>
        </Fragment>

    );
}

export default Forgot
