import React from "react";
import "./getmobileapp.css";
import { apple, google } from "../../assets";
const Getmobileapp = () => {
  return (
    <div className="actionNavBtn">

      <div className="googleClass">
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.siteat">
          <div className="google">
            <img src={google} alt="apple download" />
            <div>
              <ul>
                <li>GET IT ON</li>
                <li>Google Play</li>
              </ul>
            </div>
          </div>
        </a>
      </div>

      <div className="appleClass">
        <a target="_blank" href="https://apps.apple.com/us/app/siteat/id1612327270">
          <div className="apple">
            <img src={apple} alt="apple download" />
            <div>
              <ul>
                <li>Download on the</li>
                <li>App Store</li>
              </ul>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};
export { Getmobileapp };
