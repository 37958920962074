import React, {useState, Fragment} from "react";
import {Link, useHistory} from "react-router-dom";
import Spinner from "../../../components/reviews/Spinner";
import "./login.css";
import {LoginUser} from '../../../store/actions/auth.actions';
import {Header} from "../../../components";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const [showPassword,
        setShow] = useState(false);

    const handleLoading = () => {
        setIsLoading(true);

        setInterval(() => {
            setIsLoading(false)
        }, 6000)
    }

    const [formData,
        setFormData] = useState({identifier: '', password: ''})

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLoading();
        LoginUser(formData, history);
    }

    const {identifier, password} = formData;

    return (
        <Fragment>
            <Header/>
            <div className="container authContent alignOnly">
                <div className="row">
                    <div className="col-lg-6 col-md-12 alignOnly sectionHeight mobileBottom">
                        <div className="authTextHolder">
                            <h2 className='sectionHead textIsBold textIsPink'>Welcome Back!</h2>
                            <p className='mt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Dolore quae tenetur commodi! Quae at sapiente recusandae, molestiae sint labore,
                                asperiores</p>

                            <div className="btnHolder mt-4">
                                <button className="defaultBtn">Go back Home
                                    <i className='far fa-angle-right'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 sectionHeight alignOnly">
                        <div className="authFormCard">
                            <h2 className="vAlign textIsPink sectionText mb-3 mt-3">Login
                            </h2>
                            <form className='w-100 mt-4' onSubmit={handleSubmit}>
                                <div className="inputHolder mt-2">
                                    <input
                                        type="text"
                                        placeholder='Email, Phone or Username'
                                        name='identifier'
                                        value={identifier}
                                        onChange={handleChange}/>
                                </div>

                                <div className='mt-4'>
                                    <div className="inputHolder alignOnly mt-2">
                                        <input
                                            type={ showPassword
                                                ? 'text'
                                                : 'password' }
                                            placeholder='Password'
                                            name='password'
                                            value={password}
                                            onChange={handleChange}/>
                                        <i
                                            className={ `fas ${showPassword
                                                ? 'fa-eye-slash'
                                                : 'fa-eye'} ` }
                                            style={ {
                                                cursor: "pointer"
                                            } }
                                            onClick={ () => setShow(!showPassword) }></i>
                                    </div>
                                </div>

                                <div
                                    className="btnHolder mt-5"
                                    style={{
                                    textAlign: "center"
                                }}>
                                    {isLoading
                                        ? <button
                                                disabled
                                                className='defaultBtn center w-100'
                                                style={{
                                                marginRight: '15px'
                                            }}>Please Wait
                                                <Spinner/>
                                            </button>
                                        : <button type='submit' className="defaultBtn w-100">Login</button>}

                                </div>

                                <div
                                    className="mt-4"
                                    style={{
                                    textAlign: "right"
                                }}>
                                    <Link to='/forgot' className='textIsPink sectionText--small'>Forgot Password?</Link>
                                </div>

                                <div className="mt-4 vAlign">
                                    <p className=" sectionText--mid textIsPink mb-0 pb-0">Don't have an account?
                                        <Link to='/register' className='mx-1'>Register Here</Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
};

export default Login;
