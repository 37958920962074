import React from "react";
import { Counter } from "../counter";

const ProductCard = ({ details, cartFunctions }) => {
  const { product_name, product_amount, product_id } = details;
  return (
    <div className="m_block w-100" key={details.product_id}>
      <div className="justify-between">
        <span className="m_width">{product_name}</span>
        <span className="m_width" style={{ whiteSpace: "nowrap" }}>
          $ {product_amount}
        </span>
      </div>
      <div className="justify-between mt-2">
        <Counter cartFunctions={cartFunctions} details={details} />
        <span
          className="colorPrimary m_width"
          style={{ color: "#FF5353", cursor: "pointer" }}
          onClick={() =>
            cartFunctions({
              type: "deleteFromCart",
              payload: { id: product_id },
            })
          }
        >
          Remove
        </span>
      </div>
    </div>
  );
};
export { ProductCard };
