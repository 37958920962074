import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore } from 'redux'
import rootReducer from './store/reducers';
import ReactDOM from 'react-dom';

require('dotenv').config()
const store = createStore(rootReducer);
const rootElement=document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>, rootElement
);
serviceWorker.unregister();