import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "../../api";
import requestProcessor from "../../api/requestProcessor";
import { calculateAmount } from "../../const";
export const GET_ALL_STADIUMS = "GET_ALL_STADIUMS";
export const GET_HISTORY = "GET_HISTORY";
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const PAYMENT_LOADING = "PAYMENT_LOADING";
export const UPDATE_TIP="UPDATE_TIP";
export const GET_CART = "GET_CART";
export const GET_ALL_STALLS = "GET_ALL_STALLS";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const GET_GENERAL_SETTINGS = 'GENERAL_SETTINGS';

export const getAllStadium = (dispatch) => {
    // dispatch({ type: AUTH_LOADING });
    return requestProcessor.sendGet(`${baseURL()}/venues`).then((res) => {
        if (res && String(res.status).startsWith("2")) {
            // console.log(res.data.data);
            dispatch({ type: GET_ALL_STADIUMS, payload: res.data.data });
        }
        return;
    }).catch((err) => {
        console.log(err, "err");
    }).finally(() => {});
};

export const getStalls = (dispatch, vendorId) => {
    // dispatch({ type: AUTH_LOADING });

    return requestProcessor.sendGet(`${baseURL()}/stalls?vendor_id=${vendorId}`).then((res) => {
        if (res && String(res.status).startsWith("2")) {
            // console.log(res.data.data);
            dispatch({ type: GET_ALL_STALLS, payload: res.data.data });
        }
        return;
    }).catch((err) => {
        console.log(err, "err");
    }).finally(() => {});
};

export const getStadium = async(id) => {
    return await requestProcessor.sendGet(`${baseURL()}/venues/${id}`).then(async(res) => {
        console.log(res);
        if (res && String(res.status).startsWith("2")) {
            return { data: res.data.data, vendors: await getVendors(id) };
        }
        return;
    }).catch((err) => {
        console.log(err, "err");
    }).finally(() => {});
};

export const getVendors = (id) => {
    return requestProcessor.sendGet(`${baseURL()}/vendors?venue_id=${id}`).then((res) => {
        // console.log('yooo',res.data)
        if (res && String(res.status).startsWith("2")) {
            return res.data.data;
        }
        return;
    }).catch((err) => {
        console.log(err, "err");
    }).finally(() => {});
};

export const getOrderHistory = (dispatch) => {
    // dispatch({ type: AUTH_LOADING });

    return requestProcessor.sendGet(`${baseURL()}/orders`).then((res) => {
        if (res && String(res.status).startsWith("2")) {
            console.log(res);
            dispatch({ type: GET_HISTORY, payload: res.data.data });
            return res.data.data;
        } else {
            dispatch({ type: GET_HISTORY, payload: [] });
        }
        return;
    }).catch((err) => {
        console.log(err, "err");
    }).finally(() => {});
};

export const CheckoutStage1 = async(payload, createOrderData, history, element, stripe) => {
    const newPayload = [];
    // return;
    
    let total = 0
    payload.forEach((element) => {
        total = total + Number(element.product_amount)
        newPayload.push({ product_id: element.product_id, quantity: element.product_qty,add_ons:element.add_ons,amount:element.product_amount });
    });

    return await fetch(`${baseURL()}/cart`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sitEatID")}`,
            Accept: "application/json"
        },
        body: JSON.stringify({ cart_items: newPayload,amount:total })
    }).then((response) => {
        if (!response.ok) {
            throw new Error("HTTP status " + response.status);
        }
        return response.json();
    }).then((contents) => {
        if (contents.success) {
            return pay(createOrderData, history, element, stripe);
        }
    }).catch((err) => console.log(err));
};

export const vendorDetails = async(slug) => {
    return await requestProcessor.sendGet(`${baseURL()}/vendors/${slug}`).then((res) => {
        // console.log(res)
        if (res && res.status === 200) {
            return res.data;
        } else {
            return [];
        }
    }).catch((err) => {
        console.log(err, "err");
        return [];
    }).finally(() => {});
};

export const getProducts = async(slug, index) => {
        return await requestProcessor.sendGet(`${baseURL()}/products?vendor_id=${slug}${index
        ? `&category_id=${index}`
        : ""}`).then((res) => {
        if (res && res.status === 200) {
            // console.log(res)
            return res.data;
        } else {
            return [];
        }
    }).catch((err) => {
        console.log(err, "err");
        return [];
    }). finally(() => {});
};

export const getProductCategories = async(id) => {
    return await requestProcessor.sendGet(`${baseURL()}/categories?vendor_id=${id}`).then((res) => {
        if (res && res.status === 200) {
            // console.log(res)
            return res.data;
        } else {
            return [];
        }
    }).catch((err) => {
        console.log(err, "err");
        return [];
    }). finally(() => {});
};

export const updateTip = (value,dispatch)=>{
    return dispatch({type: UPDATE_TIP, payload: value});
}

export const addNewProduct = (product, dispatch) => {
    // toast("Item successfully to cart!");
    return dispatch({type: ADD_NEW_PRODUCT, payload: product});
};

export const pay = async(testData, history, elements, stripe) => {
    var body = {
        payment_method: testData.payment_method,
        currency: testData.currency,
        txn_ref: testData.reference,
        amount: testData.amount,
        vendor_id: JSON
            .parse(localStorage.getItem("sitEatVendor"))
            .id,
        stall_id: localStorage.getItem("stallId") ?? 1,
        venue_id: localStorage.getItem("sitEatVenue") ?? 1,
        description: "food purchase"
    };

    return await fetch(`${baseURL()}/orders`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sitEatID")}`,
            Accept: "application/json"
        },
        body: JSON.stringify(body)
    }).then((res) => res.json()).then((res) => {
        if (res.success) {
            toast("Payment Successful!");
            localStorage.setItem("payment", "success");

            history.push({pathname: "/order_history", state: true});
        }
    });
};

export const payIntent = async(state, details, history, element, stripe) => {
    const amountDetails = calculateAmount(state)
    const breakdown = {
        convenience_fee:amountDetails.convenienceFee,
        donation:Number(state.tip),
        subtotal:amountDetails.totalOrderAmount,
        total:amountDetails.totalAmount,
        tax:0
    }

    localStorage.setItem("sitEatUserDetail", JSON.stringify({
        ...JSON.parse(localStorage.getItem("sitEatUserDetail")),
        first_name: details.first_name,
        last_name: details.last_name
    }));

    return await fetch(`${baseURL()}/transactions`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sitEatID")}`,
            Accept: "application/json"
        },
        body: JSON.stringify({
            currency: "USD",
            amount: Number(amountDetails.totalAmount),
            payment_method: "stripe",
            type: "checkout",
            vendor_id: JSON
                .parse(localStorage.getItem("sitEatVendor"))
                .id,
            stall_id: localStorage.getItem("stallId") ?? 1,
            // 'venue_id': localStorage.getItem("sitEatVenue") ?? 1,
            ...details,
            breakdown
        })
    }).then((response) => {
        if (!response.ok) {
            response
                .json()
                .then((res) => {
                    console.log("data is: ", res);
                    if (res
                        ?.errors && res.errors.length > 0) {
                        let message = Object.keys(res.errors);

                        toast(res.errors[message[0]][0]);
                    }
                });

            throw new Error("HTTP status " + response.status);
        }
        return response.json();
    }).then(async(contents) => {
        if (contents.success) {
            console.log("contents", contents);

            await stripe
                .confirmCardPayment(contents.data.client_secret, {
                payment_method: {
                    card: element
                }
            })
                .then((result) => {
                    console.log("stripe result", result);
                    if (result.error) {
                        toast(result.error.message);
                    } else {
                        // The payment has been processed!
                        if (result.paymentIntent.status === "succeeded") {
                            contents.data.amount = amountDetails.totalOrderAmount;
                            CheckoutStage1(state.orders, contents.data, history, element, stripe);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            console.log("some");
        }
    }).catch((err) => console.log(err));
};

//REVIEWS ACTIONS

export const getReviews = async(productId, vendorId, dispatch) => {
    try {
        const res = await axios.get(`https://api.siteat.org/api/v2/reviews?vendor_id=${vendorId}&product_id=${productId}`);
        console.log(res.data);
        localStorage.setItem('reviews', JSON.stringify(res.data.data));
    } catch (err) {
        console.log(err)
    }
}

export const sendReview = async(formData) => {
    try {
        const res = await axios.post("https://api.siteat.org/api/v2/reviews", formData);
        if (res && res.data && res.data.status.startsWith(2)) {
            toast("Review Post Successful");
            console.log(res && res.data);
        } else {
            console.log(res);
        }
    } catch (err) {
        console.log(err);
    }
};

export const deleteReview = (id) => {
    axios
        .delete(`'https://api.siteat.org/api/v2/reviews/${id}`)
        .then((res) => {
            console.log(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const vendorSearch = async(query, dispatch) => {
    try {
        const res = await axios.get(`https://api.siteat.org/api/v2/search?query=${query}`)
        console.log(res.data.data);
        const data = res.data && res.data.data;
        localStorage.setItem('searchResults', JSON.stringify(data))
    } catch (error) {
        console.log(error);
    }
};

export const getGeneralSettings = (dispatch) => {
	return requestProcessor
		.sendGet(`${baseURL()}/site-settings`)
		.then((res) => {
			if (res && String(res.status).startsWith("2")) {
                console.log('re',res.data.data)
                localStorage.setItem("generalSettings",JSON.stringify(res.data.data))
				dispatch({ type: GET_GENERAL_SETTINGS, payload: res.data.data });
                return res.data
			}
			return;
		})
		.catch((err) => {
			console.log(err, "err");
		})
		.finally(() => { });
};
