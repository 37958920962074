import React from 'react'
import { Link } from 'react-router-dom';
import { frenchFries, phone, footer } from '../../assets';
import { Getmobileapp } from '../getmobileapp'
import { FooterNote } from '../footernote';


const SubFooter = ({ withFries }) => {
    return (
        <div>

        <div className="container demo subFooter" dta-aos={"fade-in"}>

                    <div className='mt-4 subfooterContent' data-aos='fade-up'>
                    <div className='subfooterText'>

                        <p>We are also available on</p>
                        <p>Android and IOS</p>



                        <div className='Getmobileapp'>
                        <Getmobileapp />
                        </div>



                    </div>
                        <img src={phone} alt="want to work with us?" />
                    </div>
                    {
                        withFries &&
                        <img src={frenchFries} alt="" />
                    }

                    {/* <label>Operate food store or a restaurant? <a href="https://vendor.siteat.org/signup" target="_blank" rel="noopener noreferrer" className="colorPrimary workWithUs mx-2">Work with us</a></label>
                    <span >
                        <Link className='textIsPink' to={"/request_demo"}>
                            Request demo
                            </Link>
                    </span> */}



        </div>

        <FooterNote />

        </div>
    );
}

export { SubFooter }

