import axios from "axios";
import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthenticatedLayout, CheckoutTab } from "../components";

const MainRoute = ({ Component, path, exact, isAuthenticated, ...rest }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Component]);

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(props) => {
        return (
          <AuthenticatedLayout
            history={props.history}
            isAuthenticated={isAuthenticated}
          >
            <Component {...rest} {...props} />
          </AuthenticatedLayout>
        );
      }}
    />
  );
};

const PrivateRoute = ({
  Component,
  path,
  exact,
  isAuthenticated,
  payment,
  ...rest
}) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Component]);

  const { pathname } = useLocation();

  useEffect(() => {
    axios.interceptors.response.use(undefined, (error) => {
      console.log("error loading this thing")
      if (error.response.status === 401) {

        localStorage.removeItem("sitEatUserDetail");
        localStorage.removeItem("sitEatID");
        toast("Login timed out, please login to continue your transactions ")

        setTimeout(() => {
          window.location.href = "/";
        }, 1200);
      }

      return Promise.reject(error)
    })
  }, [pathname])

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(props) => {
        return (
          <CheckoutTab
            payment={payment}
            history={props.history}
            isAuthenticated={isAuthenticated}
          >
            <Component {...rest} {...props} />
          </CheckoutTab>
        );
      }}
    />
  );
};

export { MainRoute, PrivateRoute };
