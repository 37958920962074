import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { calculateAmount } from "../../const";
import * as Actions from "../../store/actions";
import { SelectStallModal } from "../modal";
import { ProductCard } from "../productCard";
import "./index.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Checkout = ({ payment, page }) => {
  var subtitle;

  const dispatch = useDispatch();
  const orders = useSelector(({ orders }) => orders.orders);
  const state = useSelector((state) => state.orders);
  const vendorDetails = useSelector(({ orders }) => orders.vendorDetails);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [reload, setReload] = useState(Math.random());
  const [selectStall, setSelectStall] = useState(false);

  const addToCart = ({ id, name, price }) => {
    let customStore = orders;
    const existingItemKey = customStore.findIndex(
      (element) => element.product_id === id
    );

    //if the product already exists in the product store.
    if (existingItemKey >= 0) {
      customStore[existingItemKey].product_qty += 1;
    } else {
      let payload = {
        product_id: id,
        product_name: name,
        product_amount: price,
        product_qty: 1,
      };
      // customStore.push(payload)
      customStore = payload;
    }

    // console.log(customStore, orders, existingItemKey);
    Actions.addNewProduct(customStore, dispatch);
  };

  const subTractFromCart = ({ id }) => {
    let customStore = orders;
    const existingItemKey = customStore.findIndex(
      (element) => element.product_id === id
    );

    //if the product already exists in the product store.
    if (existingItemKey >= 0) {
      if (customStore[existingItemKey].product_qty > 1) {
        customStore[existingItemKey].product_qty -= 1;
      } else customStore.splice(existingItemKey, 1);
    }
    Actions.addNewProduct(customStore, dispatch);
  };

  const deleteFromCart = ({ id }) => {
    let customStore = orders;
    const existingItemKey = customStore.findIndex(
      (element) => element.product_id === id
    );

    //if the product already exists in the product store.
    if (existingItemKey >= 0) {
      customStore.splice(existingItemKey, 1);
    }
    Actions.addNewProduct(customStore, dispatch);
  };

  const cartFunctions = ({ type, payload }) => {
    const { id, name, price } = payload;

    switch (type) {
      case "addToCart":
        addToCart({ id, name, price });
        setReload(Math.random());
        break;

      case "subTractFromCart":
        subTractFromCart({ id });
        setReload(Math.random());
        break;

      case "deleteFromCart":
        deleteFromCart({ id });
        setReload(Math.random());
        break;

      default:
        setReload(Math.random());
        break;
    }
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (vendorDetails.id) {
      Actions.getStalls(dispatch, vendorDetails.id);
    }
  }, [orders, vendorDetails, dispatch, orders]);

  const amountDetails= calculateAmount(state)

  


  return (
    <>
      <div className="order isRelative" key={reload}>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Success order Notification"
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Your order is successfully placed
          </h2>
        </Modal>

        <p>Your Order</p>
        <p>{vendorDetails?.company_name ?? "..."}</p>

        <div
          className="m_block"
          style={{
            flex: 1,
            // overflow: "auto",
          }}
        >
          {orders?.map((item, index) => (
            <ProductCard
              details={item}
              key={index}
              cartFunctions={cartFunctions}
            />
          ))}
        </div>

        <div className="bottom">
          <div
            style={{
              padding: "0.2em 0em",
              border: "none",
            }}
            className="justify-between"
          >
            <span className="m_width">Items</span>
            <span className="m_width">{orders.length}</span>
          </div>

          <div
            style={{
              padding: "0.2em 0em",
              border: "none",
            }}
            className="justify-between"
          >
            <span className="m_width">Sub Total</span>
            <span className="m_width">$ {amountDetails.subTotalShow}</span>
          </div>

          <div
            style={{
              padding: "0.2em 0em",
              border: "nosne",
              alignItems: "center",
            }}
            className="justify-between"
          >
            <div className="m_width">
              Convenience Fee
              <div class="tooltip">
                {" "}
                <i className="fas fa-question" />
                <span class="tooltiptext">
                  This fee helps support the SitEat platform and covers a broad
                  range of operating costs, including background checks and
                  customer support{" "}
                </span>
              </div>
            </div>

            <span className="m_width">
              $ {amountDetails.convenienceFeeShow}
            </span>
          </div>
          {state.tip ? (
            <div
              style={{
                padding: "0.2em 0em",
                border: "none",
              }}
              className="justify-between"
            >
              <span className="m_width">Tip</span>
              <span className="m_width">$ {state.tip}</span>
            </div>
          ) : null}

          <div
            style={{
              padding: "0.2em 0em",
              border: "none",
            }}
            className="justify-between"
          >
            <span className="m_width">Total</span>
            <span className="m_width">$ {amountDetails.totalAmountShow}</span>
          </div>
          {/* <CardElement /> */}

          {/* <form onSubmit={(visible) ? null : handleCheckout} style={{ padding: "0.6em 0.5em", border: "none" }}>
                    <CardElement />
                    <button type="submit"
                    className={`justify-between primaryBG checkoutBtn ${visible ? "visible" : ""}`}>
                        <span>{visible ? "Processing..." : "Checkout"}</span>
                    </button>
                </form> */}

          {payment ? (
            <></>
          ) : (
            // <div className={`${page ? "fix" : ""}`}>
            <button
              style={{
                padding: "0.6em 0.5em",
                border: "none",
              }}
              onClick={
                orders.length > 0
                  ? () => {
                      setSelectStall(true);
                    }
                  : () =>
                      toast("Add an item to cart before proceeding to checkout")
              }
              // to={orders.length > 0 ? { pathname: "/otp", state: true } : "#"}
              // to={"#"}
              className={`justify-between primaryBG textIsWhite checkoutBtn visible }`}
            >
              <span className="m_width">{"Checkout"}</span>
              <span className="m_width">$ {amountDetails.totalAmountShow}</span>
            </button>
            // </div>
          )}
        </div>
      </div>

      {selectStall && <SelectStallModal toggleFunc={setSelectStall} />}
    </>
  );
};

export default Checkout;
