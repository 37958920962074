import React from 'react'
import "./divider.css"

const Divider = () => {
    return (
        <div className="divider"/>
    )
}

export { Divider }
