import * as Actions from "../actions";

const initialState = {
  loading: false,
  token: null,
  userDetails: null,
  loginError: null,
  signupError: null,
  alert: null,
  identifier: null,
};

export const AuthReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case Actions.AUTH_LOGIN:
      return {
        ...state,
        token: actions.payload,
        loading: false,
      };
    case Actions.AUTH_LOGOUT:
      console.log("Logout triggered");
      return {
        ...state,
        token: null,
      };
    case Actions.USER_DETAILS:
      return {
        ...state,
        userDetails: actions.payload,
      };
    case Actions.AUTH_ERROR:
      return {
        ...state,
        error: actions.payload,
      };
    case Actions.AUTH_ERROR_SIGNUP:
      return {
        ...state,
        signupError: actions.payload,
      };
    case Actions.AUTH_ALERT:
      return {
        ...state,
        alert: actions.payload,
      };
    case Actions.AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Actions.AUTH_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    case Actions.AUTH_PHONE:
      return {
        ...state,
        identifier: actions.payload,
      };
    default:
      return state;
  }
};
