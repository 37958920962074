// icons
import logo from "./images/logo/logo2.png";
import menuTab from "./icons/menu.svg";
import cart from "./icons/cart.svg";
import bill from "./icons/bill.svg";
import menu from "./icons/menu.svg";
import recycle from "./icons/recycle.svg";
import support from "./icons/support.svg";
import chat from "./icons/chat.svg";
import docs from "./icons/docs.svg";
import privacy from "./icons/privacy.svg";
import faq from "./icons/faq.svg";
import terms from "./icons/terms.svg";
import booth from "./icons/booth.svg";
import whiteBooth from "./icons/boothWhite.svg";
import arrowDown from "./icons/arrowDown.svg";
import creditCard from "./icons/credit-card.svg";
import sale from "./icons/sale.svg";
import whiteSale from "./icons/sale-copy.svg";
import whiteUser from "./icons/Icon awesome-user.svg";
import redBurger from "./icons/burger.svg";
import redHeart from "./icons/heart.svg";
import stadium from "./icons/stadium(Red).svg";
import aboutUs from "./icons/aboutUs.svg";
import arrowCTA from "./icons/arrowCTA.svg";
import searchIcon from "./icons/search.svg";
import location from "./icons/location.svg";
import queue from "./icons/queue.svg";
import shop from "./icons/shop.svg";
import blackcart from "./icons/blackcart.svg";
import arrowright from "./icons/arrowright.svg";
import arrowScroll from "./icons/arrowScroll.svg";
import yellowheart from "./icons/yellowheart.svg";
import searchicon from "./icons/searchicon.svg";


// images
import one from "./images/one.png";
import two from "./images/two.png";
import three from "./images/three.png";
import four from "./images/four.png";
import five from "./images/five.png";
import six from "./images/six.png";
import phone from "./images/phone.png";
import apple from "./images/apple.png";
import google from "./images/google.png";
import popeye from "./images/popeye.png";
import KFCItem from "./images/KFCItem.png";
import mcdonaldsItem from "./images/mcdonaldsItem.png";
import starbucks from "./images/starbucks.png";
import banner1 from "./images/banner1.png";
import banner2 from "./images/banner2.svg";
import kfc from "./images/kfc.png";
import mcdonalds from "./images/mcdonalds.png";
import wendys from "./images/wendys.png";
import demoFood from "./images/demoFood.png";
import smallImg from "./images/img.png";
import subFooter from "./images/subFooter/subFooter@2x.webp";
import frenchFries from "./images/french-fries/french-fries_2x.webp";
import hbanner10 from "./images/headBanner01/banner.webp";
import hbanner11 from "./images/headBanner01/banner_2x.webp";
import hbanner20 from "./images/headBanner02/burger.webp";
import hbanner21 from "./images/headBanner02/burger_2x.webp";
import hbanner30 from "./images/headBanner03/pizza.webp";
import hbanner31 from "./images/headBanner03/pizza_2x.webp";
import howItWorks from "./images/services/services.webp";
import chicken from "./images/meals2/chicken.webp";
import breakfast from "./images/meals/brkfast.webp";
import logoWheels from "./images/logoWheels.png"
import authBadge from "./images/signupBadge.png";
import otpImage from "./images/otpImage.jpeg";
import logo192 from "./images/logo192.png";
import redefine from "./images/redefine.png";

// exports
export {
    // icons
    searchicon,
    blackcart,
    arrowright,
    arrowScroll,
    yellowheart,
    logo,
    privacy,
    faq,
    terms,
    bill,
    cart,
    chat,
    docs,
    menuTab,
    menu,
    recycle,
    support,
    booth,
    creditCard,
    sale,
    whiteSale,
    whiteUser,
    subFooter,
    location,
    queue,
    redBurger,
    shop,
    redHeart,
    arrowDown,
    whiteBooth,
    stadium,
    aboutUs,
    arrowCTA,
    searchIcon,

    // images
    one,
    two,
    three,
    four,
    five,
    six,
    phone,
    KFCItem,
    mcdonaldsItem,
    starbucks,
    apple,
    google,
    popeye,
    banner1,
    banner2,
    kfc,
    wendys,
    mcdonalds,
    demoFood,
    smallImg,
    frenchFries,
    hbanner10,
    hbanner11,
    hbanner20,
    hbanner21,
    hbanner30,
    hbanner31,
    chicken,
    breakfast,
    howItWorks,
    authBadge,
    logoWheels,
    otpImage,
    logo192,
    redefine
}