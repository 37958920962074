import React, { useEffect } from "react";
import Checkout from "../checkout";
import { Divider } from "../divider";
import { Footer } from "../footer";
import { Header } from "../header";
import "./layout.css";

const AuthenticatedLayout = ({ history, children, isAuthenticated }) => {
  // authenticated props will come from the redux store or localstorage
  useEffect(() => {
    // if (!isAuthenticated) {
    //     history.replace("/")
    // }
  }, [isAuthenticated, history]);

  return <>{isAuthenticated && children}</>;
};

const AuthLayout = ({ history, children, isAuthenticated }) => {
  useEffect(() => {
    if (isAuthenticated) {
      history.replace("/");
    }
  }, [isAuthenticated, history]);

  return <>{!isAuthenticated && children}</>;
};

const CheckoutTab = ({ history, children, isAuthenticated, payment }) => {
  // authenticated props will come from the redux store or localstorage
  useEffect(() => {
    if (!isAuthenticated) {
      history.replace("/");
    }
  }, [isAuthenticated, history]);

  return (
    <>
      <Header />

      <main className="flex">
        <div>
          {children}
          <Divider />
          <Footer halfScreen />
        </div>
        <div>
          <Checkout key={Math.random()} payment={payment} page={false} />
        </div>
      </main>
    </>
  );
};

export { AuthenticatedLayout, AuthLayout, CheckoutTab };
