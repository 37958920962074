import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import "./footer.css";

const Footer = ({ halfScreen }) => {

    useEffect(() => {
    }, [halfScreen])

    return (
        <footer className={`container dull end ${halfScreen ? "halfScreen" : ""}`}>
            <div className="flex">

                <ul className="half" data-aos={"fade-up"}>
                    <li>About Siteat</li>
                    <li><NavLink to="/about_us">About Us</NavLink></li>
                    <li><NavLink to="/frequently_asked_questions">Faq</NavLink></li>
                    <li><NavLink to="/contact_us">Contact</NavLink></li>
                </ul>

                <ul className="half" data-aos={"fade-up"}>
                    <li>Policies</li>
                    <li><NavLink to="/terms_and_conditions">Terms & Conditions</NavLink></li>
                    <li><NavLink to="/privacy_policy">Privacy Policies</NavLink></li>
                </ul>

                <ul className="half" data-aos={"fade-up"}>
                    <li>FOR RESTAURANTS</li>
                    <li><a href="https://vendor.siteat.org/signup" target="_blank" rel="noopener noreferrer">Join SitEat</a></li>
                </ul>
            </div>
            <p>&copy; Copyright {new Date().getFullYear()} Siteat. All rights Reserved</p>
        </footer>
    )
}

export { Footer }