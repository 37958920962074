const prod_url = "https://api.siteat.org/api/v2";
const dev_url = "https://dev-api.siteat.org/api/v2";

export const baseURL = () => {
  return window.location.host === "app.siteat.org" ? prod_url : dev_url;
};

export const imgUrl = () => {
  return "http://dev-api.siteat.org/storage/";
};
