import React, {useState, Fragment} from 'react';
import { useHistory } from 'react-router';
import Spinner from '../../../components/reviews/Spinner';
import {Link} from 'react-router-dom';
import {Header} from '../../../components';
import "../login/login.css";
import {registerUser} from '../../../store/actions';

const SignUp = () => {

    const history = useHistory();

    const [formState,
        setFormState] = useState({
        first_name: "",
        last_name: '',
        username: "",
        phone: "",
        email: "",
        password: ""
    })

    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }
    const [showPassword,
        setShow] = useState(false);
    const [isLoading,
        setIsLoading] = useState(false);

    // useEffect(() => { validations     if (
    // Object.values(formState).includes("") ||         formState.phone.length < 11
    // ||         formState.password.length < 6     ) {         setCanSubmit(false)
    //    }     else {         setCanSubmit(true)     } }, [formState])

    const handleLoading = () => {
        setIsLoading(true);

        setInterval(() => {
            setIsLoading(false)
        }, 6000)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLoading();
        registerUser(formState, history);
    }

    const {
        first_name,
        last_name,
        email,
        username,
        phone,
        password
    } = formState;
    return (
        <Fragment>
            <Header/>
            <div className="container authContent alignOnly">
                <div className="row">
                    <div className="col-lg-6 col-md-12 alignOnly sectionHeight mobileBottom">
                        <div className="authTextHolder">
                            <h2 className='sectionHead textIsBold textIsPink'>Let's Get Started!</h2>
                            <p className='mt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Dolore quae tenetur commodi! Quae at sapiente recusandae, molestiae sint labore,
                                asperiores</p>

                            <div className="btnHolder mt-4">
                                <button className="defaultBtn">Go back Home
                                    <i className='far fa-angle-right'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 sectionHeight alignOnly mt-5">
                        <div className="authFormCard">
                            <h2 className="vAlign textIsPink sectionText mb-3 mt-3">Register
                            </h2>
                            <form className='w-100 mt-4' onSubmit={handleSubmit}>

                                <div className="mt-3 alignOnly">
                                    <div className="inputHolder mx-2">
                                        <input
                                            type="text"
                                            placeholder='First Name'
                                            name='first_name'
                                            value={first_name}
                                            onChange={handleChange}/>
                                    </div>

                                    <div className="inputHolder mx-2">
                                        <input
                                            type="text"
                                            placeholder='Last Name'
                                            name='last_name'
                                            value={last_name}
                                            onChange={handleChange}/>
                                    </div>
                                </div>

                                <div className="inputHolder mt-4">
                                    <input
                                        type="email"
                                        placeholder='Email'
                                        name='email'
                                        value={email}
                                        onChange={handleChange}/>
                                </div>

                                <div className="inputHolder mt-4">
                                    <input
                                        type="text"
                                        placeholder='Username'
                                        name='username'
                                        value={username}
                                        onChange={handleChange}/>
                                </div>

                                <div className="inputHolder mt-4">
                                    <input
                                        type="text"
                                        placeholder='Phone'
                                        name='phone'
                                        value={phone}
                                        onChange={handleChange}/>
                                </div>

                                <div className='mt-4'>
                                    <div className="inputHolder alignOnly mt-2">
                                        <input
                                            type={showPassword
                                            ? 'text'
                                            : 'password'}
                                            placeholder='Password'
                                            name='password'
                                            value={password}
                                            onChange={handleChange}/>
                                        <i
                                            className={`fas ${showPassword
                                            ? 'fa-eye-slash'
                                            : 'fa-eye'} `}
                                            style={{
                                            cursor: "pointer"
                                        }}
                                            onClick={() => setShow(!showPassword)}></i>
                                    </div>
                                </div>

                                <div
                                    className="btnHolder mt-5"
                                    style={{
                                    textAlign: "center"
                                }}>
                                    {isLoading
                                        ? <button
                                                className='defaultBtn center'
                                                style={{
                                                marginRight: '15px'
                                            }}>Please Wait
                                                <Spinner/>
                                            </button>
                                        : <button type='submit' className='defaultBtn'>Register</button>
}

                                </div>

                                <div className="mt-4 vAlign">
                                    <p className=" sectionText--mid textIsPink mb-0 pb-0">Already have an account?
                                        <Link to='/login' className='mx-1'>Login Here</Link>
                                    </p>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default SignUp