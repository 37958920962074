import React from "react";
import "./index.css";
const Counter = ({ cartFunctions, details }) => {
  const { product_name, product_amount, product_id, product_qty } = details;
  return (
    <div className="flex">
      <h4
        className="cartControls"
        onClick={() =>
          cartFunctions({
            type: "subTractFromCart",
            payload: { id: product_id },
          })
        }
      >
        &minus;
      </h4>
      <span className="numberBox">{product_qty}</span>
      <h4
        className="cartControls"
        onClick={() =>
          cartFunctions({
            type: "addToCart",
            payload: {
              id: product_id,
              name: product_name,
              price: product_amount,
            },
          })
        }
      >
        +
      </h4>
    </div>
  );
};

const Counter2 = () => {
  return (
    <div className="counter2">
      <span className="controls">&minus;</span>
      <h5 className="numberBox">1</h5>
      <span className="controls">+</span>
    </div>
  );
};
export { Counter, Counter2 };
