import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

function Error() {
  return (
    <div className="error center">
      <div>
        <h2 className="vAlign">Oops!</h2>
        <div className="imgHolder pt-4 pb-4">
          <img src="/error.svg" className="w-100" alt="" />
        </div>

        <div className="pt-5 vAlign">
          <p className="textIsRed sectionText--big pb-3">
            You seem lost, would you like to go back home?
          </p>
          <Link to="/" className="defaultBtn">
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Error;
