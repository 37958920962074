import React from 'react';
import './reviews.css';

function Spinner({big}) {
    return (
        
        <div className={` ${big && 'big'} spinnerHolder alignOnly ml-1 center`}>
            <div className="spinner"></div>
        </div>
    )
}

export default Spinner;
