import React from "react";
import { Link } from "react-router-dom";
import "./footernote.css";
// import { footer } from '../../assets';

const FooterNote = () => {
  return (
    <div className="footerNote">
      <div className="dark-overlay-footerNote">
        <div className="footerNote-content">
          <div className="footerNote-content-partner">
            <p>We are partnering with vendors</p>
          </div>
          <div className="footerNote-content-join">
            <p>
              Join more than 500 vendors that are registered on SitEat in over
            </p>
            <p>200 locations across the United states.</p>
          </div>

          <button className="footerNote-Btn">Learn more</button>
        </div>
      </div>

      <div className="footerNote-suggest">
        <p>
          Couldn't find your preferred venue? <span>Suggest a Venue</span>{" "}
        </p>
      </div>
    </div>
  );
};

export { FooterNote };
