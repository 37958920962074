// Stripe import
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Aos from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
// css files linked
// global CSS
import './App.css';
// App router
import AppRoute from './appRoute';
// component css
import "./components/divider/divider.css";
import "./components/footer/footer.css";
import "./components/header/header.css";
import "./components/layout/layout.css";
import "./components/modal/modal.css";
// page css
import "./pages/app/aboutUs/about.css";
import "./pages/app/contactUs/contact.css";
import "./pages/app/eventCenter/eventCenter.css";
import "./pages/app/faq/faq.css";
import "./pages/app/favouriteList/favouriteList.css";
import "./pages/app/home/home.css";
import "./pages/app/mealOrder/meals.css";
import "./pages/app/offers/offers.css";
import "./pages/app/orderHistory/orderHistory.css";
import "./pages/app/payments/payments.css";
import "./pages/app/privacyPolicy/privacy.css";
import "./pages/app/termsAndConditions/terms.css";
import "./pages/app/vendorRegistration/vendorRegistration.css";
import * as Actions from "./store/actions";






const stripePromise = loadStripe(window.location.host === "app.siteat.org" ? process.env.REACT_APP_stripe_live_pk : process.env.REACT_APP_stripe_test_pk);
// const stripePromise = loadStripe(window.location.host === "app.siteat.org" ? "pk_live_MSy5j6P81uiBTxBFKvfT3Og8006rEEOfKQ" : "pk_test_DLxezaNW6EoRSs1nE3PkV8Tj00FUFPRFNW");
Aos.init();



function App() {
  console.log('ues')
  const dispatch = useDispatch();
  Actions.getGeneralSettings(dispatch);
  React.useEffect(()=>{
    
  })
  return (
    <Elements stripe={stripePromise}>
      <AnimatePresence onExitComplete={true}>
        <AppRoute />
      </AnimatePresence>
    </Elements>
  );
}

export default App;